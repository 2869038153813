<template>
  <div>
    <div class="row">
      <!-- <div

        class="col-12 col-sm-6 col-lg-4"
        v-for="conta in contas"
        :key="`conta_${conta.id}`"
        @click="select(conta)"
      >
        <div class="cardConta">
          <i class="fa fa-wallet"></i>
          <div class="tit">
            {{ conta.nome }}
          </div>
          <div class="val">
            {{ conta.saldo | currencyMask }}
              <small>{{ conta.saldo_futuro | currencyMask }}</small> 
          </div>
            <div class="data" v-if="conta.banco">
            <b>{{ conta.banco }}</b>
            <br />
            <div class="d-flex">
              <small>
                <label><b>Agencia</b></label>
                {{ conta.agencia }}
              </small>
              <small class="ml-2">
                <label><b>Conta</b></label>
                {{ conta.conta }}
              </small>
            </div>
          </div> 
        </div>
      </div> -->
      <div class="col-12 d-flex justify-content-between">
        <div class="cardConta" @click.prevent="openCarteiras">
          <div class="tit"><i class="fa fa-cogs" /> Gerenciar Carteiras</div>
        </div>
        <div
          v-if="contasSelecionadas && contasSelecionadas.length > 0"
          class="card"
        >
          <div class="card-body text-center">
            <div>
              <b>Saldo Total Selecionado</b>
            </div>
            <div>
              {{
                contasSelecionadas.reduce((ret, vl) => {
                  ret += vl.saldo;
                  return ret;
                }, 0) | currencyMask
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="col-12 p-4 text-center" v-if="loading">
          <b-spinner /> carregando...
        </div>
        <b-table
          v-if="!loading"
          :items="contas"
          
          :fields="[
            {
              key: 'sel',
              label: '',
            },
            {
              key: 'nome',
              title: 'Conta',
              sortable: true,
            },
            {
              key: 'saldo',
              label: 'Saldo Atual',
              class: 'text-center',
              sortable: true,
            },
            {
              key: 'opc',
              text: '',
              label: '',
              title: '',
            },
          ]"
          
          selectable
          stacked="sm"
          @row-selected="selecionar"
          ref="tbSelecionada"
          hover
        >
          <template #head(sel)>
            <b-checkbox v-model="selectAll" @change="selecionarTudo" />
          </template>
          <template #cell(sel)="row">
            <b-checkbox v-model="row.item.sel" />
          </template>
          <template #cell(saldo)="row">
            {{ row.item.saldo | currencyMask }}
          </template>
          <template #cell(opc)="row">
            <b-button
              size="lg"
              large
              variant="success"
              @click="select(row.item)"
              ><i class="fa fa-wallet"
            /></b-button>
          </template>
        </b-table>
      </div>
    </div>

    <b-modal
      no-enforce-focus
      @hide="closeCarteiras"
      size="lg"
      id="modal-carteiras"
      title="Gerenciar Carteiras"
      hide-footer
    >
      <carteiras :noTitle="true" />
    </b-modal>
  </div>
</template>

<script>
import CarteiraLib from "../../../libs/Carteiras";
import Carteiras from "../../../views/Configuracoes/Financeiro/Carteiras.vue";
export default {
  components: { Carteiras },
  props: {
    carteiraSelecionada: Object,
  },
  mounted() {
    this.carregarContas();
  },

  data() {
    return {
      loading: true,
      contas: [],
      itemEdit: null,
      selectAll: false,
    };
  },
  watch: {},
  computed: {
    contasSelecionadas() {
      return this.contas.filter((it) => it.sel);
    },
  },
  methods: {
    selecionarTudo() {
      this.contas.forEach((it) => (it.sel = this.selectAll));
    },
    selecionar(item) {
      // console.log(123, item);
      // if (item && item[0]) this.select(item[0]);
      // this.$refs.tbSelecionada.clearSelected();
      if (item && item.length > 0) item[0].sel = !item[0].sel;
      this.$refs.tbSelecionada.clearSelected();
    },
    async carregarContas() {
      this.loading = true;
      this.contas = (await CarteiraLib.getCareteiras()).data
        .sort((a, b) => {
          return a.ordem > b.ordem ? 1 : -1;
        })
        .map((it) => ({ ...it, sel: false }));
      this.loading = false;
      console.log("conta,", this.contas);
    },
    openCarteiras() {
      this.$bvModal.show("modal-carteiras");
    },
    closeCarteiras() {
      this.carregarContas();
    },
    select(conta) {
      this.$emit("select", conta);
    },
  },
};
</script>

<style lang="scss" scoped>
.cardConta {
  min-height: 100px !important;
  margin: 5px;
  padding: 10px;
  border: 1px solid rgb(235, 235, 235);
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.3s;
  color: rgb(143, 143, 143);
  i {
    font-size: 60px;
    position: absolute;
    opacity: 0.05;
    color: rgb(163, 162, 162);
    right: 30px;
    top: 15px;
    transition: all 0.1s;
  }

  .data {
    left: 50%;
    top: 18px;
    font-size: 10px;
    transition: all 0.1s;
  }

  .val {
    font-weight: bolder;
    font-size: 23px;
    bottom: 15px;
    left: 53px;
    opacity: 0.2;
    transition: all 0.1s;
    small {
      font-size: 15px;
      color: red;
    }
    @media only screen and (max-width: 575px) {
      font-size: 16px;
      small {
        font-size: 10px;
      }
    }
  }

  .tit {
    font-weight: 100;
    font-size: 16px;
    transition: all 0.1s;
  }

  &:hover {
    border-color: rgb(206, 206, 206);
    box-shadow: 0px 0px 6px #00000010;
    .tit {
      font-weight: bolder;
    }
    i {
      font-size: 80px;
    }
    .val {
      text-shadow: 0px 0px 10px #00000020;
      opacity: 1;
    }
  }
}
</style>
