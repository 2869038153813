<template>
  <div>
    <div v-if="pagina == 0" class="p-0">
      <lista-carteiras
        @select="selecionarCarteira"
        ref="listCarteirasObj"
        :carteiraSelecionada="carteiraSelecionada"
        @update="update"
      />
    </div>
    <div v-if="pagina == 1 && carteiraSelecionada" class="p-0 pl-0">
      <div class="row">
        <div class="col-12 col-sm-8">
          <h3 class="mb-2">
            
            <div v-if="pagina == 1" class="p-2 pl-2">
              <small>
                <i class="fa fa-wallet" /> Conta selecionada:
                {{ carteiraSelecionada.nome }}
                <br v-if="$store.state.isMobile" />
                <small class="ml-sm-4">
                  <small>
                    <a href="#" @click.prevent="alterarConta"
                      ><i class="fa fa-sync-alt" /> Alterar Conta</a
                    >
                  </small>
                </small></small
              >
              
            </div>
          </h3>
        </div>
        <div
          class="col-12 col-sm-4 text-center text-sm-right justify-content-center"
        >
          <b-form-group>
            <b-form inline>
              <b-select
                :options="meses"
                v-model="mesSelecionado"
                style="max-width: 100px"
              />
              <b-select
                :options="anos"
                class="ml-2"
                v-model="anoSelecionado"
                style="max-width: 100px"
              />
            </b-form>
          </b-form-group>
          <div v-if="carteiraSelecionada && pagina == 1">
            <span class="text-info font-weight-bold"
              >Saldo: {{ carteiraSelecionada.saldo | currencyMask }}</span
            ><br />
            <span class="text-danger"
              ><small
                >Futuro:{{ carteiraSelecionada.saldo_futuro | currencyMask }}
              </small></span
            >
          </div>
        </div>
      </div>
      <movimentacao-lista
        :carteiraSelecionadaId="carteiraSelecionada.id"
        :mes-selecionado="mesSelecionado"
        :ano-selecionado="anoSelecionado"
        @atualizar="update"
      />
    </div>
  </div>
</template>

<script>
import ListaCarteiras from "../Carteiras/ListaCarteiras.vue";
import MovimentacaoLista from "./MovimentacaoLista.vue";
import moment from "moment";
import CarteiraLib from "../../../libs/Carteiras";
// import SelectCarteira from "./SelectCarteira.vue";

export default {
  components: {
    ListaCarteiras, MovimentacaoLista,
    // SelectCarteira
  },

  props: {},
  mounted() {},
  data() {
    return {
      pagina: 0,
      carteiraSelecionada: null,
      meses: [
        { value: 1, text: "Janeiro" },
        { value: 2, text: "Fevereiro" },
        { value: 3, text: "Março" },
        { value: 4, text: "Abril" },
        { value: 5, text: "Maio" },
        { value: 6, text: "Junho" },
        { value: 7, text: "Julho" },
        { value: 8, text: "Agosto" },
        { value: 9, text: "Setembro" },
        { value: 10, text: "Outubro" },
        { value: 11, text: "Novembro" },
        { value: 12, text: "Dezembro" },
      ],
      anos: [-3, -2, -1, 0, 1, 2, 3].map((i) => {
        return moment().add(i, "y").format("YYYY");
      }),
      mesSelecionado: parseInt(moment().format("MM")),
      anoSelecionado: parseInt(moment().format("YYYY")),
      itemEdit: null,
    };
  },
  watch: {
    paginaTitulo: function () {
      return this.pagina == 0
        ? "Selecione uma carteira/conta"
        : this.pagina == 1
        ? "Movimentação"
        : "";
    },
  },
  computed: {},
  methods: {
    async update() {
      if (this.carteiraSelecionada) {
        let res = await CarteiraLib.getCareteiras({
          id: this.carteiraSelecionada.id,
        });
        console.log("busca", res);
        if (res && res.data) {
          this.carteiraSelecionada = res.data[0];
        }
      }
    },
    selecionarCarteira(carteira) {
      console.log(carteira);
      this.carteiraSelecionada = carteira;
      this.pagina = 1;
    },
    alterarConta() {
      this.pagina = 0;
    },
    atualizar() {
      console.log(1111111, this.$refs);
      this.$root.$refs.listCarteirasObj.carregarContas();
    },
  },
};
</script>

<style lang="scss" scoped></style>
