import { render, staticRenderFns } from "./ListaCarteiras.vue?vue&type=template&id=681d8c84&scoped=true"
import script from "./ListaCarteiras.vue?vue&type=script&lang=js"
export * from "./ListaCarteiras.vue?vue&type=script&lang=js"
import style0 from "./ListaCarteiras.vue?vue&type=style&index=0&id=681d8c84&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "681d8c84",
  null
  
)

export default component.exports