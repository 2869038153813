var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-between"},[_c('div',{staticClass:"cardConta",on:{"click":function($event){$event.preventDefault();return _vm.openCarteiras.apply(null, arguments)}}},[_vm._m(0)]),(_vm.contasSelecionadas && _vm.contasSelecionadas.length > 0)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body text-center"},[_vm._m(1),_c('div',[_vm._v(" "+_vm._s(_vm._f("currencyMask")(_vm.contasSelecionadas.reduce((ret, vl) => { ret += vl.saldo; return ret; }, 0)))+" ")])])]):_vm._e()]),_c('div',{staticClass:"col-12"},[(_vm.loading)?_c('div',{staticClass:"col-12 p-4 text-center"},[_c('b-spinner'),_vm._v(" carregando... ")],1):_vm._e(),(!_vm.loading)?_c('b-table',{ref:"tbSelecionada",attrs:{"items":_vm.contas,"fields":[
          {
            key: 'sel',
            label: '',
          },
          {
            key: 'nome',
            title: 'Conta',
            sortable: true,
          },
          {
            key: 'saldo',
            label: 'Saldo Atual',
            class: 'text-center',
            sortable: true,
          },
          {
            key: 'opc',
            text: '',
            label: '',
            title: '',
          },
        ],"selectable":"","stacked":"sm","hover":""},on:{"row-selected":_vm.selecionar},scopedSlots:_vm._u([{key:"head(sel)",fn:function(){return [_c('b-checkbox',{on:{"change":_vm.selecionarTudo},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}})]},proxy:true},{key:"cell(sel)",fn:function(row){return [_c('b-checkbox',{model:{value:(row.item.sel),callback:function ($$v) {_vm.$set(row.item, "sel", $$v)},expression:"row.item.sel"}})]}},{key:"cell(saldo)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("currencyMask")(row.item.saldo))+" ")]}},{key:"cell(opc)",fn:function(row){return [_c('b-button',{attrs:{"size":"lg","large":"","variant":"success"},on:{"click":function($event){return _vm.select(row.item)}}},[_c('i',{staticClass:"fa fa-wallet"})])]}}],null,false,1217747250)}):_vm._e()],1)]),_c('b-modal',{attrs:{"no-enforce-focus":"","size":"lg","id":"modal-carteiras","title":"Gerenciar Carteiras","hide-footer":""},on:{"hide":_vm.closeCarteiras}},[_c('carteiras',{attrs:{"noTitle":true}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit"},[_c('i',{staticClass:"fa fa-cogs"}),_vm._v(" Gerenciar Carteiras")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b',[_vm._v("Saldo Total Selecionado")])])
}]

export { render, staticRenderFns }